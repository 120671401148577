<template>
    <div class="max_div" v-if="vif">
        <div class="top_div">
            <div class="title_div">
                镜方详情
            </div>
            <div class="top_data_div" style="margin-top:2%">
                    <div class="text">
                        {{tableData.kname}}&nbsp;&nbsp; {{tableData.sex}} &nbsp;&nbsp; {{tableData.birthdata | tick2ymd}}
                    </div>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <div class="text">
                        {{tableData.parents}}：{{tableData.username}}&nbsp;&nbsp; {{tableData.phone}}
                    </div>
            </div>
            
            <div class="top_data_div" style="margin-top:1%">
                    <div class="text">
                        雾视屏SN码：{{tableData.sn ? tableData.sn : '暂无'}}
                    </div>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <div class="text">
                        雾视屏型号：{{tableData.devm ? tableData.devm : '暂无' }}
                    </div>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <div class="text">
                        雾视屏授权：{{tableData.cname ? tableData.cname : '暂无' }}
                    </div>
            </div>
        </div>
        <div class="bottom_div" style="margin-top:2%">

            <div class="bottom_left_div">
                <div class="left_title" style="margin-top:5%">
                    镜方列表
                </div>
                <div class="left_dataList" style="margin-top:5%">
                    <div class="left_data" style="cursor:pointer" v-for="(item,index) in visionList" :key='index' @click="getMirrorRow(item.id)">
                        {{item.ctime | tick2ymd}}镜方
                    </div>
                </div>
            </div>

            <div class="bottom_right_div">
                    <div class="right_title">
                        <div>
                            当前镜方或验光数据：{{tableData.corid}}
                        </div>
                        <div>
                            PD：{{tableData.pd}}
                        </div>
                        <div>
                            {{tableData.checktime|tick2ymd}} &nbsp;{{tableData.hospital}}
                        </div>
                    </div>

                    <div class="right_data" style="margin-top:2%">
                        <div class="right_dataList">
                            <div class="line_div">
                                <div class="row_div">
                                    序号
                                </div>
                                <div class="row_div">
                                    右眼
                                </div>
                                <div class="row_div">
                                    左眼
                                </div>
                            </div>
                            <div class="line_div">
                                <div class="row_div">
                                    裸眼
                                </div>
                                <div class="row_div">
                                    {{tableData.rev}}
                                </div>
                                <div class="row_div">
                                    {{tableData.lev}}
                                </div>
                            </div>
                            <div class="line_div">
                                <div class="row_div">
                                    矫正
                                </div>
                                <div class="row_div">
                                    {{tableData.rcv}}
                                </div>
                                <div class="row_div">
                                    {{tableData.lcv}}
                                </div>
                            </div>
                            <div class="line_div">
                                <div class="row_div">
                                    球镜
                                </div>
                                <div class="row_div">
                                    {{tableData.rs}}
                                </div>
                                <div class="row_div">
                                    {{tableData.ls}}
                                </div>
                            </div>
                            <div class="line_div">
                                <div class="row_div">
                                    柱镜
                                </div>
                                <div class="row_div">
                                    {{tableData.rc}}
                                </div>
                                <div class="row_div">
                                    {{tableData.lc}}
                                </div>
                            </div>
                            <div class="line_div">
                                <div class="row_div">
                                    轴位
                                </div>
                                <div class="row_div">
                                    {{tableData.rax}}
                                </div>
                                <div class="row_div">
                                    {{tableData.lax}}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="span" style="margin-top:2%">审核镜方：单光低雾</div>
                    <div class="input_data"  style="margin-top:2%">
                        <div class="line_input1">
                            <div>&nbsp;</div>
                            <div>右眼</div>
                            <div>左眼</div>
                        </div>
                        <div class="line_input">
                            <div>球镜</div>
                            <div class="row_input"><el-input :size="tabsize"  oninput="value=value.replace(/[^-+\d.]/g,'')"  v-model="submitData.shrs" placeholder=""></el-input></div>
                            <div class="row_input"><el-input :size="tabsize"  oninput="value=value.replace(/[^-+\d.]/g,'')" v-model="submitData.shls" placeholder=""></el-input></div>
                        </div>
                        <div class="line_input">
                            <div>柱镜</div>
                            <div class="row_input"><el-input :size="tabsize"  oninput="value=value.replace(/[^-+\d.]/g,'')" v-model="submitData.shrc" placeholder=""></el-input></div>
                            <div class="row_input"><el-input :size="tabsize"  oninput="value=value.replace(/[^-+\d.]/g,'')" v-model="submitData.shlc" placeholder=""></el-input></div>
                        </div>
                        <div class="line_input">
                            <div>轴位</div>
                            <div class="row_input"><el-input :size="tabsize"  oninput="value=value.replace(/[^-+\d.]/g,'')" v-model="submitData.shrax" placeholder=""></el-input></div>
                            <div class="row_input"><el-input :size="tabsize"  oninput="value=value.replace(/[^-+\d.]/g,'')" v-model="submitData.shlax" placeholder=""></el-input></div>
                        </div>
                        <div class="line_input">
                            <div>雾视量</div>
                            <div class="row_input"><el-input :size="tabsize"  oninput="value=value.replace(/[^-+\d.]/g,'')" v-model="submitData.shrws" placeholder=""></el-input></div>
                            <div class="row_input"><el-input :size="tabsize"  oninput="value=value.replace(/[^-+\d.]/g,'')" v-model="submitData.shws" placeholder=""></el-input></div>
                        </div>

                    </div>

                    <div class="right_text" style="margin-top:2%">
                        <div class="text_right_title">
                            右眼镜方说明：
                        </div>
                        <div class="text_right_data">
                            <el-input type="textarea" :rows="3" placeholder="请输入内容" v-model="submitData.rcontent">
                            </el-input>
                        </div>
                    </div>

                    <div class="right_text" style="margin-top:2%">
                        <div class="text_right_title">
                            左眼镜方说明：
                        </div>
                        <div class="text_right_data">
                            <el-input type="textarea" :rows="3" placeholder="请输入内容" v-model="submitData.lcontent">
                            </el-input>
                        </div>
                    </div>

                    <div class="button_div">
                        <el-button :disabled="submitDM" @click="submitDataStart" :size="tabsize" type="primary">提交</el-button>
                    </div>
            </div>
        </div>
    </div>
</template>

<script>
import { get } from 'http'

    export default {
        mounted(){
            this.kid =  this.$route.query.kid
            this.submitData.kid = this.kid
            this.getMirrorList()

        },
        data(){
            return{
                titleData:[],
                visionList:[],
                inputData:'',
                kid:'',
                id:'',
                str:'',
                str1:'',
                vif:true,
                tableData:[],
                submitDM:false,
                submitData:{
                    rcontent:'',
                    lcontent:'',
                    kid:'',
                    id:'',
                    shls:'',
                    shrs:'',
                    shlc:'',
                    shrc:'',
                },
            }
        },
        // 监听尺寸按钮变化 改变表格大小
      computed: {
          tabsize() {
             return this.$store.state.tabsize
          }
      },
      methods:{
        getMirrorList(){
             this.$api.vision.getInfoList({
                kid:this.kid
            }).then(res => {
                this.visionList = res.obj
                if(this.visionList.length>0){
                    this.getMirrorRow(this.visionList[0].id)
                }
            }).catch((error) => {
                console.log(error);
            })
        },
         getMirrorRow(item){
             this.id = item
             this.$api.vision.getInfo({
                kid:this.kid,
                id:this.id
            }).then(res => {    
                this.submitData.id = item
                this.tableData  = res.obj
                let str = 'yj_sex'
                let str1 = this.tableData.sex
                this.getZDdata(str,str1)
                str =  'yj_address'
                str1 = this.tableData.hospital
                this.getZDdata(str,str1)
                str = 'ws_correct'
                str1 = this.tableData.corid
                this.getZDdata(str,str1)
                this.submitData = this.tableData
                if(this.tableData.status == 1){
                    this.submitDM = false
                }else{
                    this.submitDM = true
                }
                this.submitData['id'] = item
            }).catch((error) => {
                console.log(error);
            })
        },
        getZDdata(str,data){

            this.$api.vision.getZDdata({
                dtype:str
            }).then(res => {
               res.obj.forEach(element => {
                    if(element.k == data){

                        if(str == 'yj_sex'){
                            this.tableData.sex = element.v
                        }else if(str == 'yj_address'){
                            this.tableData.hospital = element.v
                        }else if(str == 'ws_correct'){
                            this.tableData.corid = element.v
                        }
                    }
               });
               return '未知内容'

            }).catch((error) => {
                console.log(error);
            })
        },
        submitDataStart(){
            this.$api.vision.sub({
                id:this.submitData.id,
                kid:this.submitData.kid,
                shls:this.submitData.shls == '/' ? '':this.submitData.shls,
                shrs:this.submitData.shrs == '/' ? '':this.submitData.shrs,
                shlc:this.submitData.shlc == '/' ? '':this.submitData.shlc,
                shrc:this.submitData.shrc == '/' ? '':this.submitData.shrc,
                shws:this.submitData.shws == '/' ? '':this.submitData.shws,
                shrws:this.submitData.shrws == '/' ? '':this.submitData.shrws,
                shlax:this.submitData.shlax == '/' ? '':this.submitData.shlax,
                shrax:this.submitData.shrax == '/' ? '':this.submitData.shrax,
                lcontent:this.submitData.lcontent == '/' ? '':this.submitData.lcontent,
                rcontent:this.submitData.rcontent == '/' ? '':this.submitData.rcontent,
            }).then(res => {
                if(res.status == 1){
                    this.submitDM = true
                    this.$notify.success({
                        title: 'success',
                        message: res.msg,
                        showClose: false,
                        duration: 2000,
                    });
                }else{
                    this.$notify.error({
                        title: 'error',
                        message: res.msg,
                        showClose: false,
                        duration: 2000,
                    });
                }
            }).catch((error) => {
                console.log(error);
            })
        }
      }
      
    }
</script>

<style scoped>
    .max_div{
        padding: 1%;
        width: 100%;
        height: 100%;
        background-color: #ffffff;
        overflow: auto;
    }
    .top_div{
    }
    .top_data_div{
        display: flex;
    }
    
    .bottom_div{
        margin-top: 1%;
        /* height: 80%; */
        display: flex;
    }
    .bottom_left_div{
        width: 20%;
        /* height: 100%; */
        background-color: aliceblue;
    }
    .bottom_right_div{
        width: 85%;
        height: 100%;
        margin-left: 2%;
    }
    .left_title{
        width: 100%;
        text-align: center;
    }
    .left_dataList{
        margin-top: 1%;
    }
    .left_data{
        text-align: center;
        line-height: 2.5vw;
    }
    .right_title{
        display: flex;
        justify-content: space-between;
    }
    /deep/ .el-table__body-wrapper{
        padding-bottom: 0px !important;
    }
    .input_data{
        width: 60%;
        display: flex;
        justify-content: space-between;
    }
    .line_input1{
        width:5%;
        white-space: nowrap;
    }
    .line_input1 div{
        line-height: 2.86vw;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }

    .line_input{
        width: 15%;
    }
    
    .line_input div{
        line-height: 3vw;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .row_input{
        margin-top: 0.5vw;
    }
    /deep/ .el-input__inner{
        height: 2vw;
        width: 8vw;
        text-align: center;
    }
    .text_right_data{
        margin-top: 1%;
        width: 100%;
        
    }
    /deep/ .el-textarea{
        width: 80%;
    }
    .button_div{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 2%;
        padding: 2%;
    }
      /deep/ .el-button{
        width: 25%;
        height: 3vw;
     }
     .line_div{
        height: 10vw;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
     }
     .row_div{
        height: 33.3%;
        border: 1px rgb(125, 125, 125) solid;
        width: 100%;
       display: flex;
       justify-content: center;
       align-items: center;
     }
     .right_dataList{
        width: 100%;
        display: flex;
     }
</style>